import * as prismic from '@prismicio/client'

export const usePrismicClient = () => {
  const { prismicAccessToken, prismicEndpoint } = useEnvVariables()

  if (!prismicEndpoint) {
    throw new Error('No Prismatic ENDPOINT found')
  }

  if (!prismicAccessToken) {
    throw new Error('No Prismatic ACCESS_TOKEN found')
  }

  const client = prismic.createClient(prismicEndpoint, {
    accessToken: prismicAccessToken,
    fetch
  })

  return {
    client,
    prismic,
  }
}
